import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation, useHistory } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Landing from "./pages/Landing";
import History from "./pages/History";
import Modal from "./pages/Modal";
import Deposit from "./pages/Deposit";
import Account from "./pages/Account";
import GraphTest from "./pages/GraphTest";
import AnimatedPage from './components/AnimatedPage';
import RegistrateModal from "./pages/RegistrateModal";
import PrivateRoute from "./components/PrivateRoute";

function App() {
    const [openModal, setOpenModal] = useState(false);
    const [openRegistrateModal, setOpenRegistrateModal] = useState(false);
    const location = useLocation();
    const history = useHistory();
    const [isNavigating, setIsNavigating] = useState(false);



    useEffect(() => {
        if (isNavigating) {
            const timer = setTimeout(() => {
                setIsNavigating(false);
                history.push(location.pathname);
            }, 1000); // Delay of 2 seconds

            return () => clearTimeout(timer);
        }
    }, [isNavigating, location, history]);


    const handleClose = () => setOpenModal(false);
    const handleOpen = () => setOpenModal(true);
    const handleOpenRegistate = () => setOpenRegistrateModal(true)
    const handleCloseRegistrate = () => setOpenRegistrateModal(false)

    const pageTransition = {
        initial: { opacity: 1 },
        animate: { opacity: 1 },
        exit: { opacity: 0 }
    };

    const getQueryVariable = (variable) => {
        const query = window.location.search.substring(1);
        const vars = query.split("&");
        for (let i=0; i<vars.length; i++) {
            const pair = vars[i].split("=");
            if(pair[0] == variable)
                return pair[1];
        }
        return(false);
    }

    const refLink = localStorage.getItem('refLink');
    if(!refLink) {
        const _refLink = getQueryVariable("invitetoken");
        if(_refLink){
            localStorage.setItem('refLink', _refLink);
        }
    }

    return (
        <div className="w-full h-full">
            <AnimatePresence mode={'wait'} onExitComplete={() => !isNavigating && history.push(location.pathname)}>
                <Switch location={location} key={location.pathname}>
                    <Route exact path="/">
                        <motion.div variants={pageTransition} initial="initial" animate="animate" exit="exit">
                            <AnimatedPage>
                                <Landing openModal={openModal} handleOpen={handleOpen} handleClose={handleClose} handleOpenRegistrate={handleOpenRegistate}  />
                            </AnimatedPage>
                        </motion.div>
                    </Route>
                    <PrivateRoute path="/history">
                        <motion.div variants={pageTransition} initial="initial" animate="animate" exit="exit">
                            <AnimatedPage>
                                <History handleOpen={handleOpen} handleClose={handleClose} />
                            </AnimatedPage>
                        </motion.div>
                    </PrivateRoute>
                    <PrivateRoute path="/deposit">
                        <motion.div variants={pageTransition} initial="initial" animate="animate" exit="exit">
                            <AnimatedPage>
                                <Deposit handleOpen={handleOpen} handleClose={handleClose} />
                            </AnimatedPage>
                        </motion.div>
                    </PrivateRoute>
                    <PrivateRoute path="/account">
                        <motion.div variants={pageTransition} initial="initial" animate="animate" exit="exit">
                            <AnimatedPage>
                                <Account handleOpen={handleOpen} handleClose={handleClose} />
                            </AnimatedPage>
                        </motion.div>
                    </PrivateRoute>
                </Switch>
            </AnimatePresence>
            <Modal openModal={openModal} handleClose={handleClose}  handleOpen={handleOpenRegistate} />
            <RegistrateModal openModal={openRegistrateModal} handleClose={handleCloseRegistrate}/>
        </div>
    );
}

function AppWrapper() {
    return (
        <Router>
            <Route component={App} />
        </Router>
    );
}

export default AppWrapper;
