import React, {useEffect, useState} from 'react';
import Predok from '../images/predok.svg';
import Me from '../images/me.svg';
import Me2 from '../images/referalLikeMe2.svg';
import Polygon from '../images/Polygon 12.svg';
import ReferalLikeMe from '../images/referalLikeMe.svg';
import MyReferal from '../images/myRefereal.svg';
import ReferalReferalEmpty from '../images/referalReferalEmpty.svg';
import MyEmptyReferal from '../images/myReferealEmpty.svg';
import ReferalLikeMeEmpty from '../images/referalLikeMeEmpty.svg';
import Slider from 'react-slick';
import ChevronDown from '../images/chevron-down.svg';
import Tooltip from "../components/Tooltip";
import {Web3} from "web3";
import ContractABI from "../abi/ContractABI"
import {Address, AddressTokenUsdt} from "../ContractAdress";
import {parseInt} from "lodash";

import {useWeb3ModalProvider, useWeb3ModalAccount} from '@web3modal/ethers/react'
import ContractABIToken from "../abi/ContractABIToken";


var window1;
var web3;
var account;


const SampleNextArrow = (props) => {
    const {onClick} = props;
    return (
        <div
            className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 cursor-pointer"
            onClick={onClick}
        >
            <img src={ChevronDown} alt="Next" className="-rotate-90"/>
        </div>
    );
}

const SamplePrevArrow = (props) => {
    const {onClick} = props;
    return (
        <div
            className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 cursor-pointer"
            onClick={onClick}
        >
            <img src={ChevronDown} alt="Previous" className="rotate-90"/>
        </div>
    );
}

const GraphTest = ({deposit}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [activeWallet, setActiveWallet] = useState(undefined);
    const [structure, setStructure] = useState({
        referal: {wallet: "", deposit: "", date: ""},
        referalsPrev: [],
        me: {wallet: "", deposit: "", date: ""},
        myReferals: []
    });
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const [currentLevel, setСurrentLevel] = useState(0);
    const [_currentAddress, setCurrent] = useState(null);
    const [currentAddress, setCurrentAddress] = useState({
        address: null,
        short: null,
        deposit: 0
    });
    const [upperAddress, setUpperAddress] = useState({
        address: null,
        short: null,
        deposit: 0
    });
    const [bottomAddress, setBottomAddress] = useState({
        refs: [],
        own: 0
    });

    let pDecimals, uDecimals;

    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    const {address, chainId, isConnected} = useWeb3ModalAccount();
    const {walletProvider} = useWeb3ModalProvider();

    const truncateRegex = /^(0x[a-zA-Z0-9]{6})[a-zA-Z0-9]+([a-zA-Z0-9]{6})$/;
    const truncateEthAddress = (address) => {
        const match = address.match(truncateRegex);
        if (!match) return address;
        return `${match[1]} . . . ${match[2]}`;
    };

    const doIt = async () => {

        await ConnectWalletMetamask();

        ////////////////////////
        // await BuyReferral();
        ////////////////////////

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }

    useEffect(() => {
        setСurrentLevel(0);
    }, []);

    useEffect(() => {
        doIt();
    }, [address]);

    useEffect(() => {
        if(_currentAddress)
            BuyReferral();
    }, [_currentAddress]);

    /////////////////////////////////////////////////////////
    const BuyReferral = async () => {

        try{
            setIsLoading(true);

            let contractTokenUSD = new web3.eth.Contract(ContractABIToken, AddressTokenUsdt);
            uDecimals = parseInt(await contractTokenUSD.methods.decimals().call());

            let contract = new web3.eth.Contract(ContractABI, Address);
            let currentDeposit = parseInt(await contract.methods.getUserDeposits(_currentAddress).call()) / 10 ** uDecimals;
            // console.log("BuyReferral", _currentAddress, truncateEthAddress(_currentAddress),  currentDeposit);
            setCurrentAddress({
                address: _currentAddress,
                short: truncateEthAddress(_currentAddress),
                deposit: currentDeposit
            });

            let upperAcc = await contract.methods.checkRefInviter(_currentAddress).call();
            let upperDep = parseInt(await contract.methods.getUserDeposits(upperAcc).call()) / 10 ** uDecimals;

            setUpperAddress({
                address: upperAcc,
                short: truncateEthAddress(upperAcc),
                deposit: upperDep
            });

            let refs = await contract.methods.checkNumberAllRef(_currentAddress).call();
            console.log(refs);
            let allRefs = refs[0];
            let userRefs = refs[1];
            let ownRefs = Number(refs[2]);

            let refsInfo = [];

            if(parseInt(userRefs) > 0) {

                let refIDS = (await contract.methods.checkInviterAllRefs(_currentAddress, 1, userRefs).call())[1];
                console.log(refIDS);

                for(let refID of refIDS){

                    let tmp = parseInt(await contract.methods.getUserDeposits(refID).call()) / 10 ** uDecimals;
                    refsInfo.push({
                        address: refID,
                        short: truncateEthAddress(refID),
                        deposit: tmp
                    })

                }
            }

            setBottomAddress({
                refs: refsInfo,
                own: ownRefs
            })

            setIsLoading(false);
        } catch(e){
            setIsLoading(false);
        }

    }
    /////////////////////////////////////////////////////////

    const settings = {
        className: "center",
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 3,
        swipeToSlide: true,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow/>,
        afterChange: function (index) {
            console.log(
                `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
            );
        }
    };


    const ConnectWalletMetamask = async () => {
        // setIsLoading(true);
        let NewStructure = {
            referal: {
                wallet: "",
                deposit: "",
                date: ""
            },
            referalsPrev: [],
            me: {
                wallet: "",
                deposit: "",
                date: ""
            },
            myReferals: []
        };
        /////////////////////////////////////подключение к метамаску
        try {
            if (/*window.ethereum*/ isConnected) {
                try {
                    // const accounts = await window.ethereum.request({
                    //     method: "eth_requestAccounts",
                    // });
                    // web3 = new Web3(window.ethereum)
                    web3 = new Web3(walletProvider)
                    account = address; //accounts[0];
                    // setCurrentAddress(account);

                    // setStructure({
                    //     ...NewStructure,
                    //     me: {
                    //         ...NewStructure.me,
                    //         wallet: truncateEthAddress(account),
                    //         deposit: deposit,
                    //     }
                    // })
                    // NewStructure.me.wallet = truncateEthAddress(account);
                    // NewStructure.me.deposit = deposit;

                    let contractTokenUSD = new web3.eth.Contract(ContractABIToken, AddressTokenUsdt);
                    uDecimals = parseInt(await contractTokenUSD.methods.decimals().call());

                    setTimeout(() => {
                        setCurrent(account);
                        setСurrentLevel(0);

                    }, 200);

                    // setActiveWallet(activeWallet !== undefined ? activeWallet : account)
                } catch (error) {
                    console.log("Error connecting...");
                }
            } else {
                console.log("Download Metamask");
            }

            // ///////////////////////////////////////////////////////////////////////////////////////////////////
            // let contract1 = new web3.eth.Contract(ContractABI, Address);
            //
            // // //история транзакций моя
            // // const events = await contract1.getPastEvents('Deposit', {
            // //     filter: {user: NewStructure.me.wallet},
            // //     fromBlock: 0
            // // });
            // //
            // // if (events.length > 0) {
            // //     NewStructure.referal.wallet = events[0].returnValues[2]; // Принимаем первое событие для примера
            // // }
            // console.log(NewStructure.referal.wallet)
            // if (NewStructure.referal.wallet !== "") {
            //     NewStructure.referalsPrev = await getPrevRefferals(NewStructure.referal.wallet) // undefined
            // }
            // NewStructure.myReferals = await getMyRefferals(NewStructure.me.wallet)
            //
            // setTimeout(() => setStructure(NewStructure), 200);
            // // setIsLoading(false);
        } catch (e) {
            console.log(`Error: ConnectWalletMetamask`);
        }
    }

    const getPrevRefferals = async (adress_wallet) => {
        let temp = []
        try {
            if (isConnected) {
                try {
                    // const accounts = await window.ethereum.request({
                    //     method: "eth_requestAccounts",
                    // });
                    // web3 = new Web3(window.ethereum)
                    web3 = new Web3(walletProvider)
                    account = address; //accounts[0];
                } catch (error) {
                    console.log("Error connecting...");
                }
            } else {
                console.log("Download Metamask");
            }


            let contract1 = new web3.eth.Contract(ContractABI, Address);

            contract1.events.Deposit({
                filter: {inviter: `${adress_wallet}`},
                fromBlock: 0
            }, function (error, event) {
            })
                .on('data', function (event) {
                    temp.push({
                        wallet: event?.returnValues[0],
                        deposit: parseInt(event?.returnValues[1]) / 10 ** 18,
                        date: new Date(parseInt(event?.returnValues[3]) * 1000).toJSON()
                    });
                })

        } catch (e) {
            console.error('Prev');
        }
        return temp;
    }
    const getMyRefferals = async (adress_wallet) => {
        let temp = []
        try {
            if (isConnected) {
                try {
                    // const accounts = await window.ethereum.request({
                    //     method: "eth_requestAccounts",
                    // });
                    // web3 = new Web3(window.ethereum)
                    web3 = new Web3(walletProvider)
                    account = address; //accounts[0];
                    // console.log(accounts[0]); //мой кошелек
                } catch (error) {
                    console.log("Error connecting...");
                }
            } else {
                console.log("Download Metamask");
            }


            let contract1 = new web3.eth.Contract(ContractABI, Address);

            // //мои рефералы
            // contract1.events.Deposit({
            //     filter: {inviter: `${adress_wallet}`},
            //     fromBlock: 0
            // }, function (error, event) {
            // })
            //     .on('data', function (event) {
            //         temp.push({
            //             wallet: event?.returnValues[0],
            //             deposit: parseInt(event?.returnValues[1]) / 10 ** 18,
            //             date: new Date(parseInt(event?.returnValues[3]) * 1000).toJSON()
            //         });
            //     })


        } catch (e) {
            console.log(`Error2`);
        }
        return temp;
    }

    const getStructure = async (address_wallet) => {
        if (address_wallet !== undefined) {
            setIsLoading(true)
            let newStructure = {
                referal: {wallet: structure.me, deposit: "", date: ""},
                referalsPrev: [],
                me: {wallet: address_wallet, deposit: "", date: ""},
                myReferals: []
            };
            try {
                newStructure.referalsPrev = await getPrevRefferals(newStructure.referal.wallet)
                newStructure.myReferals = await getMyRefferals(address_wallet)
                setTimeout(() => setStructure(newStructure), 200);
                setIsLoading(false);
                console.log(structure)
            } catch (e) {
                console.log(`Error: getStructure`);
            }
        }

    }

    const processUp = () => {
        if(currentAddress.address.toLowerCase() !== upperAddress.address.toLowerCase()){
            setСurrentLevel(currentLevel - 1);
            setCurrent(upperAddress.address);
        }
    }

    const processDown = (addr) => {
            setСurrentLevel(currentLevel + 1);
            setCurrent(addr);
    }

    return (
        <div className={'w-full py-12'}>
            {isLoading ? (
                <div>Loading data from blockchain...</div> // Показать индикатор загрузки
            ) : (
                <div className={'flex flex-col'}>
                    {screenWidth < 768 ? (
                        <div className={'flex flex-col justify-center items-center'}>
                            <div className={'flex flex-col justify-center items-center gap-4'}>
                                <img src={Predok} alt=""/>
                                <img className={'w-[125px] h-[160px]'} src={Me2} alt={''}/>
                            </div>
                            <div className={'w-full pt-4 slider-container'}>
                                <Slider {...settings}>
                                    {Array.from({length: 18}).map((_, index) => (
                                        <div key={index} className="flex-none w-full">
                                            <div className={'flex flex-col justify-center items-center gap-4'}>
                                                <img src={Me2} alt=""/>
                                                <img width={28} height={28} src={Polygon} alt={''}/>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    ) : (
                        <>

                            <div className={'flex md:gap-8 lg:gap-2'}>
                                <div className={'flex flex-col justify-center items-center'}>
                                    <Tooltip processUp={processUp} referal_link={upperAddress.short} deposit={upperAddress.deposit}
                                             level={currentLevel - 1}>
                                        <img src={currentLevel == 1 ? Me: Predok} alt=""/>
                                    </Tooltip>
                                    {/*
                                    ++{currentAddress.short}++
                                    ++{currentAddress.deposit}++
                                    */}
                                    <Tooltip referal_link={currentAddress.short} deposit={currentAddress.deposit} level={currentLevel}>
                                        <img src={currentLevel == 0 ? Me : Predok} alt={''}/>
                                    </Tooltip>
                                </div>
                                <div
                                    className={'flex md:flex-col lg:flex-row md:justify-start justify-end items-center gap-4'}>
                                    <div className={'flex gap-4 md:w-full lg:w-auto justify-start'}>
                                        {/*
                                        {Array.from({length: 4}).map((_, i) => (
                                            <Tooltip
                                                referal_link={structure.referalsPrev[i] ? structure.referalsPrev[i].wallet : undefined}>
                                                <img key={i}
                                                     src={structure.referalsPrev[i] ? ReferalLikeMe : ReferalLikeMeEmpty}
                                                     alt={''}
                                                     onClick={() => getStructure(structure.referalsPrev[i] ? structure.referalsPrev[i].wallet : undefined)}/>
                                            </Tooltip>
                                        ))}
                                        */}
                                    </div>
                                    <div className={'flex gap-4'}>
                                        {/*
                                        {Array.from({length: 5}).map((_, i) => (
                                            <Tooltip
                                                referal_link={structure.referalsPrev[i + 4] ? structure.referalsPrev[i + 4].wallet : undefined}>
                                                <img key={i}
                                                     src={structure.referalsPrev[i + 4] ? ReferalLikeMe : ReferalLikeMeEmpty}
                                                     alt={''}
                                                     onClick={() => getStructure(structure.referalsPrev[i] ? structure.referalsPrev[i].wallet : undefined)}/>
                                            </Tooltip>
                                        ))}
                                        */}
                                    </div>
                                </div>


                            </div>
                            <div className={'flex md:flex-col md:gap-8 lg:gap-0 lg:flex-row'}>
                                <div className={'flex md:pl-16'} >
                                    {bottomAddress.refs.map((_, i) => (
                                        <div key={i} className={'flex flex-col'}>
                                            <div>
                                                <Tooltip
                                                    referal_link={bottomAddress.refs[i].short}
                                                    level={currentLevel + 1}
                                                    deposit={bottomAddress.refs[i].deposit}
                                                    processDown={processDown}
                                                    addr={bottomAddress.refs[i].address}
                                                >
                                                    <img
                                                        src={structure.myReferals[i + 4] ? MyReferal : MyEmptyReferal}
                                                        alt={''}
                                                        onClick={() => getStructure(structure.myReferals[i] ? structure.myReferals[i].wallet : undefined)}/>
                                                </Tooltip>
                                            </div>
                                            <div className={'pl-20'}>
                                                <div className={'h-[36px] w-[55px]'}>
                                                    <img src={ReferalReferalEmpty} alt={''}/>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className={'flex md:pl-16 lg:pl-0'}>
                                    {Array.from({length: 10 - bottomAddress.own - bottomAddress.refs.length > 0? 10 - bottomAddress.own - bottomAddress.refs.length: 0}).map((_, i) => (
                                        <div key={i} className={'flex flex-col'}>
                                            <div>
                                                <Tooltip
                                                    referal_link={structure.myReferals[i] ? structure.myReferals[i].wallet : undefined}>
                                                    <img src={structure.myReferals[i] ? MyReferal : MyEmptyReferal}
                                                         alt={''}
                                                         onClick={() => getStructure(structure.myReferals[i] ? structure.myReferals[i].wallet : undefined)}/>
                                                </Tooltip>
                                            </div>
                                            <div className={'pl-20'}>
                                                <div className={'h-[36px] w-[55px]'}>
                                                    <img src={ReferalReferalEmpty} alt={''}/>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>


                        </>
                    )}
                </div>
            )}

        </div>
    );
}

export default GraphTest;
