import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'
import { useWeb3ModalAccount } from '@web3modal/ethers/react'

const projectId = process.env.REACT_APP_WALLET_CONNECT;

const worknet
    = {
    chainId: process.env.REACT_APP_NETWORK * 1,
    name: process.env.REACT_APP_NETWORK_NAME,
    // currency: 'ETH',
    // explorerUrl: 'https://etherscan.io',
    rpcUrl: process.env.REACT_APP_NETWORK_RPC
}

const metadata = {
    name: 'EYWA',
    description: 'EYWA Referal System',
    url: process.env.REACT_APP_DOMAIN,
    icons: [`${process.env.REACT_APP_DOMAIN}/eywa-logo.png`]
}

const ethersConfig = defaultConfig({
    metadata,
    enableEIP6963: true,
    enableInjected: true,
    enableCoinbase: false,
    // rpcUrl: '...',
    // defaultChainId: 1
})

export const WalletModal = createWeb3Modal({
    ethersConfig,
    chains: [worknet],
    projectId,
    enableAnalytics: false,
    allowUnsupportedChain: false,
    enableSwaps: false,
    enableOnramp: false
})


export const isAuth = () => {

    const isConnected = WalletModal.getIsConnected();
    // const token = JSON.parse(localStorage.getItem('auth'));
    // console.log(process.env.REACT_APP_WALLET_CONNECT);
    // return token;
    return isConnected;
}
